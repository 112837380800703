import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 486.000000 486.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g transform="translate(0.000000,486.000000) scale(0.100000,-0.100000)"
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd">
<path d="M0 2430 l0 -2430 2430 0 2430 0 0 2430 0 2430 -2430 0 -2430 0 0
-2430z m2571 2107 c122 -219 178 -446 166 -667 -7 -124 -27 -222 -44 -216 -8
3 -15 -5 -19 -22 -10 -46 -11 -48 -23 -22 -10 23 -11 21 -7 -15 4 -28 2 -35
-4 -25 -5 8 -10 25 -10 37 0 25 -16 39 -24 21 -5 -12 -2 21 11 107 11 79 1
314 -17 406 -19 90 -62 225 -79 242 -6 6 -125 -184 -205 -328 -9 -16 -25 -43
-36 -60 -11 -16 -25 -40 -31 -52 -7 -13 -16 -23 -21 -23 -4 0 -8 -6 -8 -14 0
-8 -5 -18 -12 -22 -6 -4 -8 -3 -5 3 8 13 -18 24 -37 17 -13 -5 -13 -2 -3 20
11 24 12 25 21 6 5 -11 13 -20 18 -20 10 0 0 42 -12 50 -4 3 -10 12 -13 20 -5
13 -6 13 -6 -1 -1 -9 -10 -26 -21 -39 -12 -14 -18 -32 -15 -44 3 -12 2 -15 -1
-8 -8 18 -24 15 -25 -5 0 -10 -3 -13 -5 -6 -3 7 11 40 30 73 20 34 36 63 36
65 0 2 24 45 53 97 30 51 68 118 84 148 29 52 109 192 176 308 17 28 34 52 37
52 3 0 26 -37 51 -83z m125 -924 c-10 -10 -19 5 -10 18 6 11 8 11 12 0 2 -7 1
-15 -2 -18z m-99 -25 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z
m93 -8 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z
m615 -351 c-3 -4 -12 -6 -20 -3 -8 4 -13 10 -10 15 3 4 12 6 20 3 8 -4 13 -10
10 -15z m-8 -48 c32 -16 36 -14 29 17 -7 25 78 -111 252 -404 103 -175 107
-158 -39 -150 -219 13 -433 72 -571 156 -27 17 -51 30 -54 30 -8 0 -144 100
-144 106 0 2 18 -5 39 -16 24 -12 42 -16 46 -10 4 6 13 5 25 -2 14 -9 24 -9
42 0 23 12 23 12 -3 7 -17 -3 -31 1 -39 10 -8 9 -22 13 -39 10 -25 -5 -25 -5
-3 5 16 7 30 7 42 0 10 -6 25 -10 32 -10 7 1 1 8 -14 16 -16 8 -28 20 -28 26
0 7 5 6 15 -2 40 -33 216 -121 301 -150 66 -23 224 -53 253 -48 19 3 16 12
-43 118 -36 63 -68 120 -73 126 -4 5 -26 44 -47 85 -29 54 -34 69 -19 56 16
-14 22 -15 27 -5 3 7 3 -2 0 -19 -4 -21 -2 -33 6 -33 6 0 8 5 5 10 -4 6 -1 17
5 25 9 11 9 18 -4 30 -11 12 -16 13 -16 4 0 -18 -14 0 -28 36 -12 30 -12 30 6
9 10 -12 27 -26 39 -33z m-57 4 c0 -5 -5 -3 -10 5 -5 8 -10 20 -10 25 0 6 5 3
10 -5 5 -8 10 -19 10 -25z m-480 -225 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10
-3 6 -1 10 4 10 6 0 11 -4 11 -10z m-1380 -625 l0 -65 -70 0 -70 0 0 65 0 65
70 0 70 0 0 -65z m200 0 l0 -65 -70 0 -70 0 0 65 0 65 70 0 70 0 0 -65z m1400
-300 l0 -365 -65 0 -65 0 0 33 c-1 32 -1 32 -18 11 -54 -64 -176 -73 -254 -18
-65 45 -99 116 -105 218 -13 195 74 312 224 304 52 -3 69 -9 103 -36 l40 -33
0 126 0 125 70 0 70 0 0 -365z m-1904 0 c68 -192 124 -353 124 -357 0 -5 -37
-8 -83 -8 l-83 0 -22 75 -22 75 -133 0 -132 0 -24 -75 -25 -75 -83 0 -83 0 34
93 c37 101 117 326 173 482 20 55 40 110 44 123 8 22 13 23 100 20 l91 -3 124
-350z m2624 245 l0 -70 145 0 145 0 0 -50 0 -50 -145 0 -145 0 0 -117 c0 -148
10 -186 52 -204 26 -10 35 -10 57 2 40 22 51 55 51 152 l0 87 66 0 66 0 -3
-117 c-4 -133 -16 -163 -82 -217 -37 -30 -43 -31 -130 -31 -86 0 -95 2 -133
30 -79 56 -79 54 -82 373 l-3 282 70 0 71 0 0 -70z m-1750 -130 c0 -70 0 -70
-27 -70 -49 0 -98 -20 -118 -48 -18 -24 -20 -46 -23 -194 l-4 -168 -69 0 -69
0 0 270 0 270 65 0 c63 0 65 -1 65 -25 0 -14 5 -25 10 -25 6 0 10 4 10 10 0
18 74 49 118 50 l42 0 0 -70z m343 59 c52 -14 101 -60 115 -107 8 -24 12 -114
12 -236 l0 -196 -70 0 c-63 0 -70 2 -70 20 0 25 -4 25 -40 -1 -40 -30 -154
-38 -213 -15 -64 25 -82 57 -82 145 l0 73 43 38 c38 34 53 40 140 56 121 21
139 28 147 54 9 29 -18 57 -64 65 -49 8 -87 -8 -106 -45 -15 -29 -18 -30 -80
-30 -64 0 -65 0 -65 27 0 66 55 131 128 153 39 12 161 12 205 -1z m1080 0 c52
-14 101 -60 115 -107 8 -24 12 -114 12 -236 l0 -196 -70 0 c-64 0 -70 2 -70
21 l0 21 -36 -23 c-43 -26 -154 -37 -206 -20 -19 6 -48 25 -64 42 -27 28 -29
36 -29 106 l0 75 42 38 c39 34 53 40 155 59 136 25 168 51 117 97 -46 42 -159
15 -159 -38 0 -16 -9 -18 -70 -18 l-70 0 0 30 c0 60 49 121 117 145 46 17 163
19 216 4z m1000 0 c52 -14 101 -60 115 -107 8 -24 12 -114 12 -236 l0 -196
-70 0 c-63 0 -70 2 -70 20 0 25 -4 25 -40 -1 -40 -30 -154 -38 -213 -15 -64
25 -82 57 -82 145 l0 73 43 38 c38 34 53 40 140 56 121 21 139 28 147 54 9 29
-18 57 -64 65 -49 8 -87 -8 -106 -45 -15 -29 -18 -30 -80 -30 -64 0 -65 0 -65
27 0 66 55 131 128 153 39 12 161 12 205 -1z m-2995 -326 c-3 -326 -3 -328
-27 -370 -35 -61 -87 -94 -160 -100 -78 -7 -141 10 -180 51 -33 34 -61 102
-61 147 0 29 0 29 63 29 l64 0 12 -40 c14 -46 46 -65 94 -54 53 11 57 39 57
369 l0 295 71 0 70 0 -3 -327z m202 57 l0 -270 -70 0 -70 0 0 270 0 270 70 0
70 0 0 -270z m255 -643 c26 -20 11 -39 -20 -27 -28 11 -58 3 -52 -13 2 -7 18
-14 35 -18 42 -9 62 -26 62 -55 0 -42 -65 -68 -111 -44 -19 11 -27 40 -9 40 5
0 21 -5 35 -12 21 -9 29 -9 42 5 18 17 22 14 -62 47 -34 13 -36 66 -2 80 32
13 61 12 82 -3z m-295 -11 c0 -10 -14 -16 -42 -18 -57 -4 -60 -31 -4 -38 56
-7 58 -28 4 -32 -39 -3 -43 -6 -46 -30 -2 -16 -9 -28 -18 -28 -11 0 -14 17
-14 80 l0 80 60 0 c46 0 60 -3 60 -14z m144 -53 c15 -37 30 -73 33 -80 3 -8
-3 -13 -16 -13 -12 0 -21 6 -21 15 0 11 -12 15 -44 15 -30 0 -46 -5 -50 -15
-3 -8 -14 -15 -25 -15 -18 0 -16 7 17 80 48 105 65 107 106 13z m319 52 c-1
-5 -11 -11 -23 -13 -17 -3 -20 -11 -21 -53 -2 -72 -2 -73 -21 -77 -16 -3 -18
5 -18 62 0 64 -1 66 -25 66 -16 0 -25 6 -25 15 0 13 12 15 68 13 38 -2 66 -7
65 -13z m193 -59 c19 -41 34 -77 34 -80 0 -13 -38 -5 -44 9 -3 10 -19 15 -45
15 -25 0 -41 -5 -45 -15 -6 -16 -36 -21 -36 -6 0 5 14 41 32 80 43 95 58 94
104 -3z m107 34 c36 -51 49 -51 41 0 -6 36 -5 40 15 40 20 0 21 -5 21 -80 0
-96 -10 -101 -61 -34 l-34 46 -3 -46 c-3 -38 -7 -46 -23 -46 -17 0 -19 7 -19
80 0 70 2 80 18 80 9 0 30 -18 45 -40z m217 20 c13 -13 20 -33 20 -60 0 -57
-26 -80 -90 -80 l-50 0 0 80 0 80 50 0 c37 0 55 -5 70 -20z m212 3 c22 -20 23
-42 2 -71 -15 -21 -15 -25 0 -41 21 -23 20 -31 -3 -31 -11 0 -28 11 -39 25
-11 14 -27 25 -36 25 -10 0 -16 -9 -16 -25 0 -16 -6 -25 -15 -25 -12 0 -15 16
-15 80 l0 80 52 0 c34 0 58 -6 70 -17z m158 2 c0 -11 -12 -15 -45 -15 -38 0
-45 -3 -45 -20 0 -17 7 -20 45 -20 33 0 45 -4 45 -15 0 -11 -12 -15 -45 -15
-33 0 -45 -4 -45 -15 0 -11 12 -15 45 -15 33 0 45 -4 45 -15 0 -12 -13 -15
-60 -15 l-60 0 0 80 0 80 60 0 c47 0 60 -3 60 -15z m52 -47 l3 -63 46 -3 46
-3 28 66 c39 90 55 88 103 -12 l37 -78 3 78 3 77 53 0 c60 0 77 -13 75 -56 0
-16 2 -38 6 -50 12 -39 -17 -54 -106 -54 -64 0 -79 3 -79 15 0 11 -12 15 -44
15 -30 0 -46 -5 -50 -15 -4 -11 -23 -15 -81 -15 l-75 0 0 80 c0 63 3 80 15 80
10 0 15 -16 17 -62z m458 -2 l0 -65 42 -3 c23 -2 43 -9 46 -15 3 -10 -13 -13
-57 -13 l-61 0 0 80 c0 64 3 80 15 80 12 0 15 -14 15 -64z m220 49 c0 -11 -12
-15 -46 -15 -38 0 -45 -3 -42 -17 2 -13 15 -19 46 -21 28 -2 42 -8 42 -18 0
-10 -12 -14 -45 -14 -33 0 -45 -4 -45 -15 0 -11 12 -15 45 -15 33 0 45 -4 45
-15 0 -12 -13 -15 -60 -15 l-60 0 0 80 0 80 60 0 c47 0 60 -3 60 -15z"/>
<path d="M2210 3975 c0 -5 5 -17 10 -25 5 -8 10 -10 10 -5 0 6 -5 17 -10 25
-5 8 -10 11 -10 5z"/>
<path d="M2633 3688 c0 -21 5 -34 9 -30 4 4 3 21 -2 37 -9 30 -9 30 -7 -7z"/>
<path d="M2661 3674 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3330 3150 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M3350 3121 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z"/>
<path d="M2950 2901 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z"/>
<path d="M2890 2876 c0 -2 7 -9 15 -16 13 -11 14 -10 9 4 -5 14 -24 23 -24 12z"/>
<path d="M2680 2082 c-106 -52 -84 -272 30 -297 33 -7 77 9 102 37 60 69 41
225 -32 263 -37 19 -57 19 -100 -3z"/>
<path d="M815 2093 c-20 -65 -38 -126 -41 -135 -5 -16 3 -18 80 -18 98 0 97
-1 67 82 -11 29 -27 81 -37 116 -9 34 -20 65 -24 67 -5 3 -25 -48 -45 -112z"/>
<path d="M2235 1915 c-22 -7 -59 -18 -83 -24 -50 -14 -77 -49 -68 -86 14 -55
97 -58 156 -5 27 24 34 39 38 80 2 27 3 49 1 49 -2 -1 -22 -7 -44 -14z"/>
<path d="M3340 1921 c-8 -4 -42 -14 -74 -20 -94 -21 -132 -75 -85 -122 43 -43
149 0 170 69 14 49 9 84 -11 73z"/>
<path d="M4315 1915 c-22 -7 -59 -18 -83 -24 -50 -14 -77 -49 -68 -86 14 -55
97 -58 156 -5 27 24 34 39 38 80 2 27 3 49 1 49 -2 -1 -22 -7 -44 -14z"/>
<path d="M1622 1235 c-16 -34 -15 -35 8 -35 21 0 24 12 11 43 -6 17 -8 16 -19
-8z"/>
<path d="M2131 1231 c-15 -28 -14 -31 13 -31 19 0 23 4 19 20 -8 29 -20 33
-32 11z"/>
<path d="M2430 1220 c0 -46 2 -50 24 -50 29 0 56 24 56 50 0 26 -27 50 -56 50
-22 0 -24 -4 -24 -50z"/>
<path d="M2640 1245 c0 -24 3 -26 33 -23 24 2 32 8 32 23 0 15 -8 21 -32 23
-30 3 -33 1 -33 -23z"/>
<path d="M3240 1256 c0 -12 10 -16 36 -16 45 0 42 24 -4 28 -24 2 -32 -1 -32
-12z"/>
<path d="M3102 1235 c-16 -34 -15 -35 8 -35 21 0 27 24 12 48 -6 9 -12 5 -20
-13z"/>
<path d="M3240 1190 c0 -18 5 -21 33 -18 20 2 32 8 32 18 0 10 -12 16 -32 18
-28 3 -33 0 -33 -18z"/>
</g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
